.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
  display: block;
  max-width: 480px;
  min-width: 480px;
  height: 205.2px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px 0px;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  background-attachment: scroll;
  background-clip: border-box;
  background-image: none;
  background-origin: padding-box;
  background-size: auto;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-image-slice: 100%;
  border-image-width: 1;
  line-height: 20px;
  font-family: "Nunito Sans";
  font-size: 14px;
  box-sizing: border-box;
  text-size-adjust: 100%;
  align-items: center;
}

.dialogBody {
  margin-bottom: 0px;
  margin-left: 20px;
  font-size: 15px;
}
.dialogTitle {
  margin-bottom: 10px;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  display: block;
  font-family: "Nunito Sans";
  font-size: 20px;
  height: 26.4px;
  line-height: 20px;
  text-size-adjust: 100%;
  unicode-bidi: isolate;
  width: 390px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-left: 30px;
  border-bottom: #ccc;
  border-width: 1px;
  text-align: center;
}

.dialogBtn {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 400px;
  margin-left: 30px;
  margin-right: 35px;
  margin-top: 30px;
}

.dialogBtn :hover {
  background-color: #0056b3;
}

.passkeyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.passkeyText {
  margin-left: 75px;
  margin-right: 38px;
}
.copyIcon {
  min-width: 32px;
  height: 25px;

  margin-bottom: 6px;
}
.copyIcon:hover {
  cursor: pointer;
}
