.container {
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 10px;
  align-items: center;
  background: center / cover;
  padding: 12px 22px 0px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
.header {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 40px;
  height: fit-content;
  background: var(--Surface-background-secondary, #eff1f3);
  border-radius: 0.2rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  gap: 0px;
}
.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerSpan {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  height: 22px;
  padding: 12px, 16px, 12px, 16px;
  margin-left: 12px;
}

.backButton {
  background: var(--Surface-background-primary, #ffffff);
  height: 24px;
  width: 24px;
  border: 2px solid var(--Border-border-default, #d8d8d8);
  border-radius: 0.2rem;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #7a7a7a;
}

.backButton:hover {
  background-color: #000000;
  color: #fff;
  border-radius: 2px;
}
.mainTables {
  width: 100%;
  height: 80vh;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  border-radius: 0.5rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  margin-left: 41px;
  margin-right: 9px;
  margin-bottom: 0px;
}

.mainTable,
.mainTable2 {
  width: 100%;
}
@media (max-width: 768px) {
  .mainTables {
    grid-template-columns: 1fr;
  }
}

.mainTable {
  margin: 10px 0 0 10px;
  padding: 0;
  width: 425px;
  height: 96.5%;
  background-color: #fff5;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--Border-border-light, #e1e1e1);
}

.tableBody {
  width: 100%;
  overflow: hidden;
}
.deviceHeader {
  width: 100%;
  height: 10%;
  padding: 17.2px 5px 17.2px 15px;
  background: var(--Surface-background-secondary, #eff1f3);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
}
.deviceHeaderSpan {
  margin-top: 5px;
  margin-bottom: 50px;
}

.deviceTable {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}
.tableBody tr {
  display: flex;
}

.tableHead,
.deviceTableTd {
  flex: 1;
  padding: 20px 10px 20px 10px;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
  text-align: left;
}
.deviceTable .tBody tr:nth-child(even) {
  background-color: #fff;
}
.tbody tr {
  background-color: #fff;
}
.tBody tr:hover {
  background-color: #fafafa;
}
.tableBody::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.tableBody::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  visibility: hidden;
}
.tableBody:hover:-webkit-scrollbar-thumb {
  visibility: visible;
}

.buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.closeDetails,
.deRegisterDevice {
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 40px;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.closeBtn {
  padding: 6px 12px 6px 12px;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  height: 42px;
  width: 120px;
  background: var(--Button-Secondary-base, #d2d8dd);
}
.deregisterBtn {
  padding: 6px 12px 6px 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  height: 42px;
  width: 120px;
  background: var(--Button-Danger-base, #f84c3b);
}

.deregisterBtn:hover {
  background-color: #78231b;
  color: #fff;
}

.active {
  border-radius: 3px;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 6px, 12px, 6px, 12px;
  color: #000;
  background: var(--Pill-Success-bg-alt, #dae8bd);
  text-align: center;
}

.inactive {
  border-radius: 3px;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 6px, 12px, 6px, 12px;
  color: #000;
  background: var(--Pill-Success-bg-alt, #d893a3);
  text-align: center;
}

.passkeyText {
  font-size: 16px;
  color: #000;
  cursor: pointer;
}

.detailsSpinner {
  text-align: center;
  padding: 100px;
}

.detailsLoader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  justify-content: center;
  margin-left: 90px;
}

.mainTable2 {
  margin: 10px 0px 0 0px;
  padding: 0;
  width: 99%;
  height: 96.5%;
  background-color: #fff5;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  display: flex;
  flex-direction: column;
}
.tableHeader2 {
  width: 100%;
  height: 10%;
  background: var(--Surface-background-secondary, #eff1f3);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
}
.headerContent2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 5px 0 25px;
}

.fetchButton {
  border: 1px solid var(--Border-border-default, #000);
  border-radius: 0.2rem;
  margin-right: 10px;
  color: #7a7a7a;
  padding: 8px 9px 8px 9px;
}
.fetchButton:hover {
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
}

.datePickers {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.p {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
}

.datePicker {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.datePicker input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.datePicker:focus {
  border-color: #2587f7;
}

.datePicker::placeholder {
  color: #999;
}

.datePicker::-webkit-inner-spin-button,
.datePicker::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.datePicker::-webkit-calendar-picker-indicator {
  display: none;
}

.tableBody2 {
  max-height: calc(89% - 1.6rem);
  margin-bottom: 5px;
  background-color: #f7f3f3;
  margin: 0.4rem 0.4rem 0.4rem 0.4rem;
  border-radius: 0.4rem;
  overflow: hidden;
}

.alertsTable {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.alertsTable th,
.alertsTable td {
  padding: 0.8rem;
  text-align: center;
  transition: 1s;
  border-radius: 0.4rem;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
}
.bodyTr:nth-child(even) {
  background-color: #fff;
}

.bodyTr:hover {
  background-color: #fafafa;
}

.alertsTable th {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #e9f2f2;
  border: 1px solid var(--Border-border-light, #e1e1e1);
}

.alertsTable tbody td {
  border-left: none;
  border-right: none;
}

.alertsTable thead th {
  border-radius: 0.4rem;
  border-left: none;
  border-right: none;
  width: calc(100% / 4);
}

.tableBody2::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.tableBody2::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  visibility: hidden;
}

.tableBody2:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.spinner {
  text-align: center;
  padding: 20px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  justify-content: center;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #eff1f3;
  padding: 4px 0px 4px 0px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.prevButton,
.nextButton {
  height: 20px;
  width: 20px;
  color: #2587f7;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.disabled {
  color: #a2ceff;
}
.pageSpan {
  text-decoration: underline;
  text-decoration-color: #2587f7;
}
.tableFooter {
  margin-top: auto;
}
.noData {
  color: #ef233c;
}
