.splashScreen {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #253b54;
}

.loadingBar {
  /*font-family: 'Nunito Sans'; */
  width: 28.125rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.styleLoadingBar {
  flex: none;
  width: 60%;
  border-radius: 0.625rem;
  box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.175);
  border: 0.0625rem solid rgba(0, 0, 0, 0.15);

  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #7f7f7f 4%, #e2e2e2 25%, #7f7f7f 60%);
  background-size: 62.5rem 100%;
  height: 0.5rem;
  margin-top: 1.25rem;
}

@keyframes shimmer {
  0% {
    background-position: -62.5rem 0;
  }
  100% {
    background-position: 62.5rem 0;
  }
}
