.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
  display: block;
  max-width: 480px;
  min-width: 480px;
  height: 205.2px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px 0px;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 14px;
  box-sizing: border-box;
  text-size-adjust: 100%;
  align-items: center;
}

.title {
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  font-size: 18px;
  height: 24.4px;
  width: 100%;
  margin-left: 90px;
  margin-bottom: 40px;
  margin-top: 20px;
  border-bottom: #ccc;
  border-width: 1px;
  text-align: justify;
  align-items: center;
}

.closeButton {
  padding: 6px 12px 6px 12px;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  height: 42px;
  width: 120px;
  margin-left: 160px;
  background: var(--Button-Secondary-base, #d2d8dd);
}
