.container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.navigation {
  position: fixed;
  width: 56px;
  height: 100%;
  background: #eff1f3;
  transition: 0.5s;
  overflow: hidden;
}
.ul {
  width: 100%;
  margin-inline-start: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-block-end: 10px;
}
.li {
  position: relative;
  width: 100%;
  list-style: none;
  justify-content: space-between;
}

.li:nth-child(1) {
  margin-top: 15px;
}

.a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: white;
}

.Faicon::selection {
  color: rgb(51, 51, 51);
}
.selected {
  color: #333;
}
.Faicon {
  size: 14px;
  position: relative;
  top: 7px;
  left: 11px;
  display: block;
  min-width: 25px;
  height: 26px;
  text-align: center;
  color: #9b9b9b;
  margin-bottom: 50px;
}
