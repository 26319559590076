.main {
  background-color: #fefdfdf0;
}
.header {
  margin-top: 20px;
  color: black;
  font-size: 10px;
  margin-left: 250px;
  margin-right: 250px;
}

hr {
  border: 1px solid #d8d8d8;
  width: 100%;
}
.container {
  margin-top: 20px;
  margin-left: 250px;
  margin-right: 250px;
  border: 1px #ddd solid;
}
.ribbon {
  background-color: #f5f5f5;
  padding: 3px;
  font-size: 14px;
}
.p {
  margin-left: 5px;
}
.cardContainer {
  margin: 10px 0 10px 10px;
  border-color: rgb(91, 91, 91);
  border-width: 1px;
  display: flex;
  flex-wrap: wrap;
}
