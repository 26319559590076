.card {
  width: 180px;
  height: 250px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px;
  background-color: #fff;
  position: relative;
}

.blueContent {
  width: 100%;
  height: 20%;
  background-color: rgba(0, 0, 255, 0.5);
  background-image: linear-gradient(-90deg, #1d6bc4 1%, #0e417a 99%);
  z-index: 1;
}
.msg {
  font-size: 12px;
  color: #5e5e5e;
}
.buisnessName {
  font-size: 14px;
  color: #333333;
}
.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  z-index: 2;
  margin: auto;
  margin-top: -20px;
  position: absolute;
  top: 40px;
  right: 60px;
}
.mainContent {
  height: 70%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  width: 100%;
}

.hoverContent {
  height: 80%;
  background-color: #282828;
  z-index: 1;
  border-radius: 0 0 5px 5px;
}

.btnHover {
  min-width: 120px;
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
  color: black;
  background-color: #ffffff;
  border-color: #2587f7;
  margin-top: 80px;
  margin-left: 30px;
  margin-right: 10px;
  align-content: center;
}
.btnHover:hover {
  /* background-color: #e6e6e6e6; */
  background-color: #2587f7;
  color: #fff;
}
