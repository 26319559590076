@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  background-color: #253b54;
}
.lifelenzLogo {
  margin-top: 140px;
}

.formContainer {
  background-color: #fff;
  border-radius: 0.625rem;
  padding: 1.25rem 2.5rem;
  width: 28.4375rem;
  margin-top: 20px;
  margin-bottom: 500px;
}

.label {
  color: #333;
  font-size: 0.75rem;
  font-weight: normal;
  height: 1rem;
  margin-bottom: 0.375rem;
  margin-top: 20px;
}

.input {
  width: 25.25rem;
  height: 2.1875rem;
  border-radius: 0.1875rem;
  padding: 0.1875rem 0.5rem;
  border: 0.0625rem solid #cbcbcb;
  font-family: "Nunito Sans", sans-serif;
}
.input:focus {
  border-color: #2587f7 !important;
  outline: none;
}
.submit {
  text-align: center;
  text-transform: none;
  overflow: visible;
  min-width: 7.5rem;
  padding: 0.25rem 0.75rem;
  line-height: 1.42857143;
  border-radius: 0.25rem;
  height: 2rem;
  display: block;
  width: 100%;
  color: #fff;
  background-color: #2587f7;
  border-color: #2587f7;
  border: 0.0625rem solid transparent;
  margin-bottom: 0;
  margin-top: 1.25rem;
  font-weight: normal;
  font-family: inherit;
  font-size: 0.875rem;
  text-align: center;
  white-space: nowrap;
  position: relative;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
}
.formHead {
  text-align: center;
  text-transform: none;
  overflow: visible;
  min-width: 7.5rem;
  padding: 0.25rem 0.75rem;
  line-height: 1.42857143;
  border-radius: 0.25rem;
  height: 2rem;
  display: block;
  width: 100%;
  color: #fff;
  background-color: #2587f7;
  border-color: #2587f7;
  border: 0.0625rem solid transparent;
  margin-bottom: 0;
  font-weight: normal;
  font-family: inherit;
  font-size: 0.875rem;
  text-align: center;
  white-space: nowrap;

  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
}
.orContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0.62rem;
}

.orHr {
  flex-grow: 1;
  border: none;
  border-top: 0.0625rem solid #ccc;
  position: absolute;
  width: 24.25rem;
  z-index: 1;
}

.orText {
  background-color: #fff;
  padding: 0 0.625rem;
  color: #b1b1b1;
  font-weight: normal;
  margin-top: 20px;
  z-index: 10;
}

.formContainer form {
  margin: 0.625rem 0.875rem 0.625rem 0.875rem;
}
.spinner {
  border: 0.25rem solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 3.125rem;
  align-items: center;
  animation: spin 5s linear infinite;
  position: absolute;
  top: 4%;
  left: 35%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.invalidInput {
  border-color: red;
}

.errorMsg {
  color: red;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: block;
}
