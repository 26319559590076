.container {
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 10px;
  align-items: center;
  background: center / cover;
  padding: 10px 22px 0px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.tableHeader {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 40px;
  height: fit-content;
  background: var(--Surface-background-secondary, #eff1f3);
  border-radius: 0.2rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  gap: 0px;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerSpan {
  font-size: 19px;
  font-weight: 600;
  line-height: 21.82px;
  height: 30px;
  padding: 44px, 16px, 44px, 16px;
  margin-top: 10px;
  margin-left: 10px;
}

.datePickers {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.datePicker {
  margin-right: 20px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.datePicker input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.datePicker:focus {
  border-color: #2587f7;
}

.datePicker::placeholder {
  color: #999;
}

.datePicker::-webkit-inner-spin-button,
.datePicker::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.datePicker::-webkit-calendar-picker-indicator {
  display: none;
}

.fetchButton {
  margin-right: 15px;
  border-radius: 4px;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  padding: 8px 9px 8px 9px;
}
.fetchButton:hover {
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
}

.mainTable {
  margin: 0px 0 0 35px;
  padding: 0;
  width: 100%;
  height: 81vh;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  display: flex;
  flex-direction: column;
}
.p {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
}

.tableBody {
  width: 99%;
  height: 98%;
  background-color: #fff;
  margin: 0.4rem 0.4rem 0.4rem 0.4rem;
  border-radius: 0.6rem;
  overflow: auto;
}

.complianceErrorTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.complianceErrorTable th {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
}

.complianceErrorTable td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
}

.complianceErrorTable th {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #e9f2f2;
}

.complianceErrorTable tbody td {
  border-left: none;
  border-right: none;
}

.complianceErrorTable thead th {
  border-left: none;
  border-right: none;
  width: calc(100% / 8);
}

.bodyTr:nth-child(even) {
  background-color: #0000000b;
}

.tableBody::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.tableBody::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  visibility: hidden;
}

.tableBody:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.spinner {
  text-align: center;
  padding: 20px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  justify-content: center;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #eff1f3;
  padding: 5px 0px 5px 0px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.prevButton,
.nextButton {
  height: 20px;
  width: 20px;
  color: #2587f7;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.disabled {
  color: #a2ceff;
}
.pageSpan {
  text-decoration: underline;
  text-decoration-color: #2587f7;
}
.tableFooter {
  margin-top: auto;
}
.noData {
  color: #ef233c;
}
