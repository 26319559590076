.container {
  height: 60px;
  width: 341px;
  align-items: start;
  margin-top: 20px;
  cursor: pointer;
}
.selectorContainer {
  border: 1px solid var(--Border-border-light, #e1e1e1);
  display: flex;
  flex-direction: column;
}
.businessTitle {
  font-size: 14px;
  margin-left: 0px;
}
.div {
  display: flex;
  justify-content: space-between;
}
.icon {
  margin-top: 10px;
  margin-right: 5px;
}

.scheduleName {
  border: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  font-size: inherit;
  color: inherit;
  width: 320px;
}
.scheduleName option:first-child {
  display: none;
}
.selector {
  width: 320px;
  height: max-content;
  padding: 0px 0;
  margin: 0px 0 0 5px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 3px;
  top: 57px;
  z-index: 5;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 1;
  top: 60px;
  right: 0;
}

.dropdown {
  width: 340px;
  height: max-content;
  padding: 5px 0;
  margin: 2px 0 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  top: 57px;
  z-index: 5;
}

.dropdownItem {
  padding: 5px 10px;
  cursor: pointer;
  margin: 4px 8px;
  font-size: 14px;
}

.dropdownItem:hover {
  background-color: #3a86ff;
  color: #fff;
  border-radius: 3px;
}
