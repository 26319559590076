.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
  display: block;
  max-width: 480px;
  min-width: 480px;
  height: 205.2px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px 0px;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  align-items: center;
}

.dialogBody {
  margin-bottom: 0px;
  margin-left: 20px;
  font-size: 16px;
}
.dialogTitle {
  margin-bottom: 10px;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  display: block;
  font-family: "Nunito Sans";
  font-size: 20px;
  height: 26.4px;
  line-height: 20px;
  text-size-adjust: 100%;
  unicode-bidi: isolate;
  width: 390px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-left: 20px;
  border-bottom: #ccc;
  border-width: 1px;
  text-align: start;
}

.btnContainer {
  display: flex;
}
.okBtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 50px;
  margin-top: 30px;
  padding: 10px 20px;
  cursor: pointer;
}

.okBtn:hover {
  background-color: #0056b3;
}
.cancelBtn {
  padding: 10px 20px;
  margin-left: 130px;
  cursor: pointer;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: var(--Button-Secondary-base, #d2d8dd);
}
