.container {
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 10px;
  align-items: center;
  background: center / cover;
  padding: 12px 22px 0px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
.tableHeader {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 40px;
  height: fit-content;
  background: var(--Surface-background-secondary, #eff1f3);
  border-radius: 0.2rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  gap: 0px;
}
.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerSpan {
  font-size: 19px;
  font-weight: 600;
  line-height: 21.82px;
  height: 30px;
  padding: 44px, 16px, 44px, 16px;
  margin-top: 10px;
  margin-left: 10px;
}

.warningMessageContainer {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 40px;
  height: 34px;
  background: var(--Pill-Warning-bg-alt, #fff2d5);
  border-radius: 0.2rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
}
.warningMessage {
  padding: 10px 16px 0 16px;
  color: var(--Pill-Warning-text-alt, #664b11);
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  overflow: hidden;
}

.uploadContainer {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 40px;
  height: fit-content;
  background: #fff;
  border-radius: 0.2rem;
  border: 1px dashed var(--Border-border-light, #e1e1e1);
  gap: 0px;
  display: flex;
  justify-content: center;
}
.spanContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.orSpan {
  margin-left: 30px;
}
.browseSpan {
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  color: #3498db;
  margin-left: 5px;
}

.mainTable {
  margin: 0px 0 0 35px;
  padding: 0;
  width: 100%;
  height: 66vh;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--Border-border-light, #e1e1e1);
}

.tableBody {
  width: 99%;
  height: 98%;
  background-color: #fff;
  margin: 0.4rem 0.4rem 0.4rem 0.4rem;
  border-radius: 0.6rem;
  overflow: auto;
}

.fileUploadTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.fileUploadTable th {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
}
.fileUploadTable td {
  /* padding: 1.2rem 1rem 1.2rem 200px; */
  padding: 1rem;
  width: calc(100% / 3);
  text-align: center;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
}

.fileUploadTable th {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #e9f2f2;
}

.fileUploadTable tbody td {
  border-left: none;
  border-right: none;
}
.fileUploadTable thead th {
  border-left: none;
  border-right: none;
  width: calc(100% / 3);
}

tbody tr:nth-child(even) {
  background-color: #0000000b;
}

.tableBody::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.tableBody::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  visibility: hidden;
}
.tableBody:hover:-webkit-scrollbar-thumb {
  visibility: visible;
}

.spinner {
  text-align: center;
  padding: 20px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  justify-content: center;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.noData {
  color: #ef233c;
}
